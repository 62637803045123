import React from "react"
import { Link } from "gatsby"
import { Button, Badge } from "reactstrap"
import styled from "styled-components"

import { useAddToCart, useCart } from "../../hooks"
import CardModal from "../CardModal"
import ListSignup from "../ListSignup"
import { ShoppingCart } from "styled-icons/fa-solid/ShoppingCart"

const CartWhite = styled(ShoppingCart)`
  color: #fff;
  width: 1rem;
`

const ProductCardFooter = ({ product }) => {
  const { addToCartHandler } = useAddToCart()
  const { isProductExist } = useCart()

  const isProductAddedToCart = isProductExist(product)

  let cardFooter
  if (product.type !== "VARIABLE") {
    const { freeGiftWithSignup, ecommerceAndShipping } = product.ACFEcommerce

    if (freeGiftWithSignup === "freeGift") {
      cardFooter = <ListSignup productName={product.name} />
    } else if (
      ecommerceAndShipping !== "ecommerce" ||
      (ecommerceAndShipping === "ecommerce" && !product.price)
    ) {
      cardFooter = <CardModal product={product} />
    } else {
      cardFooter = (
        <>
          <div className="text-center mt-0">
            {/* {isProductAddedToCart && <div className="small">Added to Cart</div>} */}
            <h6 className="d-inline align-middle mr-3">
              <span className="text-center">{product.price}</span>
            </h6>

            {!isProductAddedToCart && (
              <Button
                color="cta"
                className="text-center"
                onClick={() => addToCartHandler(product, 1)}
              >
                Add to Cart
              </Button>
            )}

            {isProductAddedToCart && (
              <Link
                to="/cart"
                className="btn btn-cta text-center border-primary"
              >
                In Cart! View{" "}
                <span>
                  <CartWhite />
                </span>{" "}
              </Link>
            )}
          </div>
        </>
      )
    }
  } else {
    cardFooter = (
      <Link to={`/shop/${product.slug}`} className="btn btn-primary">
        View Details
      </Link>
    )
  }

  return cardFooter
}

export default ProductCardFooter
