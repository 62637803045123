import React, { useState } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import {
  Row,
  Col,
  InputGroup,
  InputGroupText,
  InputGroupButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap"

const ProductCategoryDropdown = ({ active }) => {
  const [category, toggleCategory] = useState(false)
  const productCategoriesData = useStaticQuery(graphql`
    {
      wpgraphql {
        productCategories(first: 100) {
          nodes {
            databaseId
            name
            slug
          }
        }
      }
    }
  `)

  return (
    <Row>
      <Col md="12">
        <div style={{ float: 'right' }}>
          <InputGroup>
            <InputGroupButtonDropdown
              addonType="append"
              direction="down"
              isOpen={category}
              toggle={() => toggleCategory(!category)}
              className="categories-dropdown"
            >
              <InputGroupText style={{ paddingTop: 6, paddingRight: 10 }}>Category</InputGroupText>
              <DropdownToggle caret outline>
                {'all' === active ? 'All' : productCategoriesData?.wpgraphql?.productCategories?.nodes?.filter(item => active === item.slug)[0].name}
              </DropdownToggle>
              <DropdownMenu>
                <Link to={`/products`} key="all">
                  <DropdownItem active={'all' === active}>All</DropdownItem>
                </Link>
                {productCategoriesData?.wpgraphql?.productCategories?.nodes?.map(
                  cat => (
                    <Link
                      to={`/product-category/${cat.slug}`}
                      key={cat.databaseId}
                    >
                      <DropdownItem active={active === cat.slug}>
                        {cat.name}
                      </DropdownItem>
                    </Link>
                  )
                )}
              </DropdownMenu>
            </InputGroupButtonDropdown>
          </InputGroup>
        </div>
      </Col>
    </Row>
  )
}

export default ProductCategoryDropdown
