import React from "react"
import { Link } from "gatsby"
import { Card, CardBody, CardImg, CardTitle, CardFooter, Col } from "reactstrap"

import ProductCardFooter from "./ProductCardFooter"

function ProductCard({ product, useAddToCart, onButtonClick }) {
  return (
    <Col md="4" lg="3" key={product.productId} className="d-flex h-auto mb-4">
      <Card className="h-100 d-flex flex-column shadow text-center zoom">
        {
          <Link to={"../shop/" + product.slug}>
            <CardImg
              top
              src={product.image && product.image.sourceUrl}
              alt={product.image ? product.image.altText : product.title}
            />
          </Link>
        }
        <CardBody className="d-flex flex-column-reverse">
          {
            <Link to={"../shop/" + product.slug}>
              <CardTitle>
                <h5>{product.name}</h5>
              </CardTitle>
              {product.onSale &&
                product.ACFEcommerce.ecommerceAndShipping === "ecommerce" && (
                  <del>
                    <h5 style={{ color: "black" }}>{product.regularPrice}</h5>
                  </del>
                )}
            </Link>
          }
        </CardBody>
        <CardFooter className="my-auto">
          <ProductCardFooter product={product} />
        </CardFooter>
      </Card>
    </Col>
  )
}

export default ProductCard
