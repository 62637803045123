import React from "react"
import { Row, Col, Container, Breadcrumb, BreadcrumbItem } from "reactstrap"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ProductCard from "../../components/product-card"
import Pagination from "../../components/pagination"
import { Inner } from "../../components/Interface/"
import ProductCategoryDropdown from "../../components/product-category-dropdown"
import constants from "../../../utils/constants"

const ProductsArchive = ({
  pageContext: { nodes, pageNumber, hasNextPage },
}) => {
  return (
    <Layout>
      <SEO
        title="Our Products"
        description={`Welcome to ${constants.BRAND_FULLNAME}`}
      />
      <Breadcrumb tag="nav" listTag="div">
        <BreadcrumbItem tag={Link} to="/">
          Home
        </BreadcrumbItem>
        <BreadcrumbItem tag={Link} to="/products">
          Products
        </BreadcrumbItem>
      </Breadcrumb>
      <Container>
        <div className="product-listing-header">
          <Row>
            <Inner>
              <Col md="12">
                <h1>Products</h1>
              </Col>
            </Inner>
          </Row>
        </div>
        <Row className="mb-3">
          <Col md="12">
            <ProductCategoryDropdown active="all" />
          </Col>
        </Row>
        <Row>
          <Col md="12">
            <div>
              <Row>
                {nodes &&
                  nodes
                    .filter(p => p)
                    .map(product => (
                      <ProductCard key={product.productId} product={product} />
                    ))}
              </Row>
            </div>
          </Col>
          <Pagination
            pageNumber={pageNumber}
            hasNextPage={hasNextPage}
            path="/products"
          />
        </Row>
      </Container>
    </Layout>
  )
}

export default ProductsArchive
